<template>
  <div class="content md no-padding flex-box vertical">
    <div class="flex-grow scroll-area" style="padding: 16px">
      <div class="niche-order" v-if="info">
        <div class="other-title">订单信息<span class="niche-order-status">{{info.statusText}}</span></div>
        <detail-list label-width="6em" class="niche-order-detail" :list="info.orderInfo"></detail-list>
        <div class="other-title">龛位信息</div>
        <detail-list label-width="6em" class="niche-order-detail" :list="info.nicheInfo"></detail-list>
        <div class="other-title">供奉信息 <span class="txt-btn" @click="editInfo" v-if="info.status == 1">修改供奉信息</span></div>
        <detail-list label-width="6em" class="niche-order-detail" :list="info.provideInfo"></detail-list>
        <template v-if="info.cat == 3">
          <div class="other-title">其他信息 <span class="txt-btn" @click="editBill">修改凭证</span></div>
          <detail-list label-width="6em" class="niche-order-detail" :list="info.otherInfo"></detail-list>
        </template>
      </div>
    </div>
    <a-space class="footer">
      <a-button @click="back">返回</a-button>
      <a-button type="primary" @click="renewal" v-if="info && info.canRenewal">续供</a-button>
    </a-space>
    <a-modal
      :title="editKey == 'bill' ? '修改凭证' : '修改供奉信息'"
      :maskClosable="false"
      :closable="false"
      :width="480"
      @ok="confirm"
      v-model="visible">
      <niche-order-form
        :step="editKey == 'bill' ? 2 : 1"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        ref="infoForm"
        modify-info
        :info="form"></niche-order-form>
    </a-modal>
  </div>
</template>

<script>

import {clone, omit} from "@/common/js/tool";
    import {dealNicheOrder, getShowPrice} from "./light";
    import detailList from "../../layouts/DetailList";
    import nicheOrderForm from "@/views/light/niche-order-form.vue";

    export default {
        name: "NicheOrderDetail",
        components: {
            nicheOrderForm,
            detailList,
        },
        data() {
            return {
                info: null,
                visible: false,
                value: "",
                editKey: "",
                form: undefined
            }
        },
        computed: {
            maxLength() {
                const info = this.info;
                return info && info.order_type == 1 ? 6 : 4
            }
        },
        created() {
            this.getOrderDetail()
        },
        methods: {
            renewal() {
                const order = this.info;
                this.$store.commit("setNicheOrder", order);
                this.$router.push({
                    name: "NicheOrderCreate",
                    query: { select: order.buddhist_niche_id, o_id: order.id },
                });
            },
            back() {
                this.$router.go(-1);
            },
            confirm() {
                this.$refs.infoForm.submit().then((form) => {
                    if (this.editKey === 'bill') {
                        this.confirmBill(form.bill);
                    } else if(this.editKey === 'info') {
                        const data = omit(form, ["note"]);
                        if (form.note) {
                          const [type, note] = form.note;
                          data.type = type;
                          data.note = note;
                        }
                        this.confirmInfo(data);
                    }
                });
            },
            confirmInfo(info) {
                this.$axios({
                    url: '/admin/mini-worship-order/update-info',
                    method: "POST",
                    data: {
                        id: this.info.id,
                        ...info
                    }
                }).then(res => {
                    if (res.error === 0) {
                        this.getDetail();
                        this.$message.success("已更新");
                        this.visible = false;
                    } else {
                        this.$message.error(res.msg || '更新失败');
                    }
                });
            },
            confirmBill(bill) {
                if (bill === this.info.bill) {
                    this.visible = false;
                    return;
                }
                const orderInfo = this.info.order_info ? JSON.parse(this.info.order_info) : {};
                orderInfo.bill = bill;
                this.$axios({
                    url: `/admin/mini-worship-order/${this.info.id}`,
                    method: "PATCH",
                    data: {
                        order_info: JSON.stringify(orderInfo)
                    }
                }).then(res => {
                    this.$message.success("已更新");
                    const otherInfo = [...this.info.otherInfo];
                    otherInfo[0].imgList = bill ? bill.split(",") : [];
                    this.$set(this.info, "otherInfo", otherInfo);
                    this.$set(this.info, "bill", bill);
                    this.$set(this.info, "order_info", res.order_info);
                    this.visible = false;
                });
            },
            editInfo() {
                const info = this.info;
                this.form = {
                  order_type: info.order_type,
                  name: info.name,
                  mobile: info.mobile,
                  note: [info.type, info.note]
                };
                this.editKey = 'info';
                this.visible = true;
            },
            editBill() {
                this.form = {
                  bill: this.info.bill
                };
                this.editKey = 'bill';
                this.visible = true;
            },
            getOrderDetail() {
                const detail = this.$store.getters.detail;
                const id = this.$route.params.id;
                if(detail && detail.type == "nicheOrder" && detail.obj.id == id) {
                    this.dealOrder(clone(detail.obj));
                } else {
                    this.getDetail();
                }
            },
            getDetail() {
                const id = this.$route.params.id;
                const url = `/admin/mini-worship-order/${id}`;
                this.$axios(url).then(res => {
                    dealNicheOrder(res);
                    this.dealOrder(res);
                });
            },
            dealOrder(info) {
                info.orderInfo = [
                    {  label: "订单编号", full: true, key: "order_num", value: info.order_num },
                    {  label: "下单时间", full: true, key: "create_time", value: info.create_time },
                    {  label: "总金额", full: true, key: "orderPrice", value: info.orderPrice },
                    {  label: "龛位", full: true, key: "lightPrice", value: info.lightPrice },
                ];
                if(info.goods && info.goods.length > 0) {
                    info.goods.forEach(g => {
                        info.orderInfo.push({
                            label: `${g.name}x${g.count}`,
                            full: true,
                            key: "" + g.id,
                            value: getShowPrice(g.price * g.count)
                        })
                    });
                }
                info.nicheInfo = [
                    {  label: "供奉时长", full: true, key: "provideTime", value: info.provideTime },
                    {  label: "供奉区域", full: true, key: "showArea", value: info.showArea},
                ];
                info.provideInfo = [
                    {  label: "供奉人", full: true, key: "name", value: info.name },
                    {  label: "手机号码", full: true, key: "mobile", value: info.mobile },
                    {  label: "祈福方向", full: true, key: "typeText", value: info.typeText },
                    {  label: "祈福寄语", full: true, key: "note", value: info.note },
                ];
                if(info.cat == 3) {
                    info.otherInfo = [
                        {  label: "凭证", full: true, key: "bill", imgList: info.bill ? info.bill.split(",") : [] },
                        {  label: "处理人", full: true, key: "processor", value: info.processor },
                    ]
                }
                this.info = info;
            }
        }
    }
</script>

<style lang="less">
.niche-order-status {
  margin-left: 1em;
  font-size: 14px;
  color: #ff8c00;
}
.niche-order-detail {
  margin: @padding-md;
}
.niche-order {
  .other-title {
    .txt-btn {
      margin-left: 3em;
      font-size: 14px;
      font-weight: normal;
    }
  }
}
</style>
