<template>
  <data-list
    ref="dataList"
    class="content"
    url="/admin/mini-worship-order"
    query="&expand=attachOrder&sort=-id&filter[f_id]=0"
    entity-name="供灯订单"
    add-btn="供奉佛像"
    hide-edit
    hide-delete
    add-route="NicheOverview"
    :custom-url="customUrl"
    :deal-list="dealOrderList"
    :action="action"
    :columns="columns"
    @detail="showDetail">
    <template v-slot:button="{form}">
      <a-button @click="exportData(form)" :loading="exporting">{{exporting ? '正在导出' : '导出数据'}}</a-button>
    </template>
    <template v-slot:action="{item}">
      <span class="txt-btn" @click.stop="renewal(item)" v-if="item.canRenewal">续供</span>
      <span class="txt-btn danger" @click.stop="refundOrder(item)" v-if="item.status == 1">退款</span>
      <span class="txt-btn" @click.stop="cancelOrder(item)" v-if="item.status == 0">取消订单</span>
    </template>
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-select
          style="width: 180px"
          placeholder="选择订单状态筛选"
          :options="statusList"
          allow-clear
          v-model="form.status"
          @change="updateList">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
          style="width: 180px"
          placeholder="选择订单来源筛选"
          :options="lightOrderCatList"
          allow-clear
          v-model="form.cat"
          @change="updateList">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="下单时间">
        <a-range-picker
          v-model="form.range"
          @change="updateList"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          style="width:240px"/>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input v-model="form.keyword" placeholder="输入供奉人/手机号查询" @keyup.enter="updateList"></a-input>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input v-model="form.num" placeholder="输入龛位编号查询" @keyup.enter="updateList"></a-input>
      </a-form-model-item>
      <a-form-model-item label="即将到期时间" v-if="form.status == 1">
        <a-select
          style="width: 180px"
          placeholder="选择时间"
          :options="expireList"
          allow-clear
          v-model="form.expire_date"
          @change="updateList">
        </a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import {dealNicheOrder, getLightOrderCatText, nicheOrderStatusList, expireList, lightOrderCatList} from "./light";
    import {downLoadBlobFile, isEmpty} from "../../common/js/tool";

    export default {
        name: "NicheOrder",
        data() {
            return {
                loading: false,
                exporting: false,
                statusList: [...nicheOrderStatusList],
                columns: [
                    {title: '订单号', dataIndex: 'order_num'},
                    {title: '下单时间', dataIndex: 'create_time'},
                    {title: '龛位编号', dataIndex: 'num'},
                    {title: '龛位价格', dataIndex: 'lightPrice'},
                    {title: '供奉物品', dataIndex: 'showGoods'},
                    {title: '总金额', dataIndex: 'orderPrice'},
                    {title: '订单状态', dataIndex: 'statusText', width: 120},
                    {title: '订单来源', dataIndex: 'catText', width: 120},
                    {title: '供奉人', dataIndex: 'name', width: 120},
                    {title: '手机号', dataIndex: 'mobile', width: 180},
                    {title: '供奉时间', dataIndex: 'provideTime'},
                ],
                expireList,
                lightOrderCatList,
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            }
        },
        methods: {
            exportData(form) {
                if(this.exporting) return;
                this.exporting = true;
                let fileName = `${new Date().pattern("yyyy-MM-dd")}`;
                let url = '/admin/mini-worship-order/export';
                const params = {};
                // if (!isEmpty(form.status)) {
                //     query += `&status=${form.status}`;
                // }
                if (form.range && form.range.length == 2) {
                    const start = form.range[0] + " 00:00:00";
                    const end = form.range[1] + " 23:59:59";
                    params.start_time = start;
                    params.end_time = end;
                    if(form.range[0] != form.range[1]) {
                        fileName = `${form.range[0]}至${form.range[1]}`
                    }
                }
                if (form.cat) {
                    params.cat = form.cat
                    fileName += `${getLightOrderCatText(form.cat)}`;
                }
                fileName += "佛像供奉订单";
                const keys = Object.keys(params);
                if(keys.length > 0) {
                    keys.forEach((k, i) => {
                        url += `${i > 0 ? '&' : '?'}${k}=${params[k]}`
                    })
                }
                this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
                    this.exporting = false;
                    downLoadBlobFile(res, fileName);
                }).catch(() => {
                    this.exporting = false;
                });
            },
            customUrl(form) {
                let res = "";
                if(!isEmpty(form.status)) {
                    res += `&filter[status]=${form.status}`;
                }
                if(form.range && form.range.length == 2) {
                    const start = form.range[0] + " 00:00:00";
                    const end = form.range[1] + " 23:59:59";
                    res += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
                }
                if(form.keyword) {
                    res += `&filter[or][0][name][like]=${form.keyword}&filter[or][1][mobile][like]=${form.keyword}`;
                }
                if(form.num) {
                    res += `&filter[num][like]=${form.num}`
                }
                if (form.status == 1 && form.expire_date) {
                    // 已支付
                    let now = Math.floor(Date.now() / 1000);
                    let end_time = now + 60 * 60 * 24 * form.expire_date;
                    res += `&filter[end_time][lte]=${end_time}`;
                }
                if (form.cat) {
                    res += `&filter[cat]=${form.cat}`;
                }
                return res;
            },
            dealOrderList(list, fn) {
                list.forEach(item => {
                    dealNicheOrder(item);
                    item.catText = getLightOrderCatText(item.cat);
                    if (item.f_id) {
                        item.children = null;
                    } else {
                        if (item.children.length) {
                            item.children?.forEach(order => {
                                order.origin_id = order.id;
                                order.id = item.id + '-' + order.id;
                                dealNicheOrder(order);
                                order.catText = getLightOrderCatText(order.cat);
                                order.refunded = order.cat != 3 && order.status == 1;
                                order.children = null;
                                order.provideTime = null;
                                order.lightPrice = null;
                            });
                        } else {
                            item.children = null;
                        }
                    }
                });
                fn();
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            showDetail({item}) {
                this.$store.commit("setDetail", { type: "nicheOrder", obj: item });
                this.$router.push({
                    name: "NicheOrderDetail",
                    params: { id: item.id },
                });
            },
            cancelOrder(item) {
                this.$confirm({
                    title: "提示",
                    content: `确定取消此订单吗？`,
                    onOk: () => {
                        this.$axios({
                            url: `/admin/mini-worship-order/${item.id}`,
                            method: "PATCH",
                            data: {
                                status: 3
                            }
                        }).then(() => {
                            this.$message.success("已取消");
                            this.updateList();
                        });
                    },
                });
            },
            renewal(order) {
                this.$store.commit("setNicheOrder", order);
                this.$router.push({
                    name: "NicheOrderCreate",
                    query: { select: order.buddhist_niche_id, o_id: order.id },
                });
            },
            refundOrder(item) {
                if(item.cat == 3) {
                    this.$confirm({
                        title: '温馨提示',
                        content: "确认退款吗？",
                        onOk: () => {
                            this.$axios({
                                url: "/admin/mini-worship-order/" + item.id,
                                method: "PATCH",
                                data: {
                                    status: 4,
                                },
                                noTempleFilter: true
                            }).then(() => {
                                this.$message.success("退款成功");
                                this.updateList();
                            });
                        },
                    });
                } else {
                    this.refundCash(item)
                }
            },
            refundCash(item) {
                let price = item.price / 100;
                this.$confirm({
                    title: '确认退款金额',
                    content: <a-input v-model={price} disabled={true} placeholder="请输入退款金额" style="width: 100%" />,
                    okText: "退款",
                    onOk: () => {
                        let error = "";
                        price = Math.round(price * 100);
                        if(price <= 0) {
                            error = "退款金额必须大于0";
                        } else if(price > item.price) {
                            error = "退款金额不能超过订单总金额";
                        }
                        if(error) {
                            this.$message.warning(error)
                        } else {
                            this.$axios({
                                url: "/admin/mini-worship-order/refund",
                                method: "POST",
                                data: {
                                    refund_fee: price,
                                    order_num: item.order_num
                                },
                                noTempleFilter: true
                            }).then(res => {
                                if(res.error == 0 || res.code == 0) {
                                    this.$message.success("退款成功");
                                    this.updateList();
                                } else {
                                    this.$message.error(res.msg || "退款失败");
                                }
                            });
                        }
                    },
                });
            },
        }
    }
</script>

<style scoped>

</style>
